<template>
  <div class="pools_page">
    <Frames/>
    <div class="pools_all">

      <div class="pools_info">

        <div class="mobHide" style="height: 100px"></div>
<!--        <div class="pools_info_d1">-->
<!--          <img src="../../assets/images/Rectangle84.png"/>-->
<!--          <img src="../../assets/images/Arrow4.png"/>-->
<!--          {{poolInfo.poolName}}-->
<!--        </div>-->

        <div class="pools_info_d2">
          {{poolInfo.poolName}}
        </div>

        <div class="info_pools">
          <div class="pool_data">
            <div class="data_top">
              <div class="data_logo">
                <img :src="poolInfo.icon"/>
              </div>
              <div class="data_title">
                <div class="name">{{poolInfo.poolName}}</div>
                <div class="biaoji">Booster</div>
              </div>
              <div class="data_info">
                <div class="da yellow">{{poolInfo.tokenName}}</div>
                <div class="xiao">Sponsor</div>
              </div>
              <div class="data_info">
                <div class="da yellow">{{poolInfo.hardCap}}</div>
                <div class="xiao">Hard Cap</div>
              </div>
              <div class="data_info">
                <div class="da yellow">{{poolInfo.currentlyRaised}}</div>
                <div class="xiao">Currently Raised</div>
              </div>
              <div class="data_info">
                <div class="da yellow">{{poolInfo.myVote}}</div>
                <div class="xiao">My Investment</div>
              </div>
              <div class="data_time">
<!--                <div class="end_time">Start Time : {{changeDate(poolInfo.startTime)}}</div>-->
<!--                <div class="end_time">End Time : {{changeDate(poolInfo.endTime)}}</div>-->
                <div class="end_time">Start Time : {{poolInfo.startTime}}</div>
                <div class="end_time">End Time : {{poolInfo.endTime}}</div>
                <div class="detail yellow trr" @click="toPool()">RETURN →</div>
              </div>
            </div>
            <div class="data_desired">
<!--              <div class="data_desired_d6 mobHide">{{poolInfo.indexNum}}</div>-->
              <div class="data_desired_d1 data_desired_d1_1">IDO Platform:</div>
              <div class="data_desired_d5">

                <div v-if="poolInfo.desiredIdoPlatform.length > 0" class="data_desired_d2" v-for="(obj, i) in poolInfo.desiredIdoPlatform" :key="i">
                  <img class="data_desired_d3" :src="obj.img"/>
                  <div>{{obj.text}}</div>
                </div>

                <div class="data_desired_tba" v-if="poolInfo.desiredIdoPlatform.length === 0">
                  <div class="data_desired_d2">
                    <div class="placeholder">TBA</div>
                  </div>
                  <div class="data_desired_d2">
                    <div class="placeholder">TBA</div>
                  </div>
                  <div class="data_desired_d2">
                    <div class="placeholder">TBA</div>
                  </div>
                </div>

              </div>

              <div class="data_desired_d4 vote" @click="openVoteDialog(poolInfo.tokenAddress)" v-if="(parseFloat(poolInfo.authorizedLimit) > 0) && !poolInfo.isEnd">
                <div>Contribute</div>
              </div>
              <div class="data_desired_d4 approve" @click="coinApproveVote(poolInfo.tokenAddress, poolInfo.poolAddress)" v-if="(parseFloat(poolInfo.authorizedLimit) === 0) && !poolInfo.isEnd">
                <div>Approve</div>
              </div>
              <div class="data_desired_d4 approve" style="cursor: not-allowed" v-if="poolInfo.isEnd">
                <div>End</div>
              </div>

            </div>
          </div>

          <div class="pol_detail_mob_sec1" style="margin: 0 50px;">
            <!-- sec text -->
            <div class="pools_info_d3" v-if="poolInfo.isVision">
              Vision
            </div>
            <div class="pools_info_d4" v-if="poolInfo.isVision"></div>
            <div class="pools_info_d5" v-if="poolInfo.isVision">
              <span style="color: #FEE108" v-if="false">TrustFi Booster</span>
              {{poolInfo.vision}}
            </div>

            <!-- sec text -->
            <div class="pools_info_d3" v-if="poolInfo.isIntro">
              Intro
            </div>
            <div class="pools_info_d4" v-if="poolInfo.isIntro"></div>
            <div class="pools_info_d5" v-if="poolInfo.isIntro">
              <span style="color: #FEE108" v-if="false">TrustFi Booster</span>
              {{poolInfo.intro}}
            </div>

            <!-- sec text -->
            <div class="pools_info_d3" v-if="poolInfo.isTeam">
              Team
            </div>
            <div class="pools_info_d4" v-if="poolInfo.isTeam"></div>
            <div class="pools_info_d5" v-if="poolInfo.isTeam">
              <span style="color: #FEE108" v-if="false">TrustFi Booster</span>
              {{poolInfo.team}}
            </div>

            <!-- sec text -->
            <div class="pools_info_d3" v-if="poolInfo.isTokenPrice">
              Token Price and Vesting
            </div>
            <div class="pools_info_d4" v-if="poolInfo.isTokenPrice"></div>
            <div class="pools_info_d5" v-if="poolInfo.isTokenPrice">
              <span style="color: #FEE108" v-if="false">TrustFi Booster</span>
              {{poolInfo.tokenPrice}}
            </div>

            <!-- sec text -->
            <div class="pools_info_d3" v-if="poolInfo.isSocialMedia">
              Social Media
            </div>
            <div class="pools_info_d4" v-if="poolInfo.isSocialMedia"></div>
            <div class="pools_info_d6" v-if="poolInfo.isSocialMedia">
              <div v-for="(media, x) in poolInfo.socialMedia" :key="x">
                <a :href="media.link" target="_blank"><img :class="x === 0 ? 'guanwang' : ''" :src="media.img"/></a>
              </div>
            </div>

            <!-- sec text -->
            <div class="pools_info_d3" v-if="poolInfo.isMilestone">
              Milestone
            </div>
            <div class="pools_info_d4" v-if="poolInfo.isMilestone"></div>
            <div class="clear" v-if="poolInfo.isMilestone">
              <div class="pools_info_d7">
                <div class="pools_info_d7_d1"></div>
                <div class="pools_info_d7_d4">
                  <div class="pools_info_d7_d2">{{poolInfo.milestone.text1}}</div>
                  <div class="pools_info_d7_d3">{{poolInfo.milestone.text2}}</div>
                </div>
              </div>
              <div class="pools_info_d7">
                <div class="pools_info_d7_d1"></div>
                <div class="pools_info_d7_d4">
                  <div class="pools_info_d7_d2">{{poolInfo.milestone.text3}}</div>
                  <div class="pools_info_d7_d3">{{poolInfo.milestone.text4}}</div>
                </div>
              </div>
              <div class="pools_info_d7">
                <div style="background: #F99F36;" class="pools_info_d7_d1"></div>
                <div class="pools_info_d7_d4">
                  <div class="pools_info_d7_d2">{{poolInfo.milestone.text5}}</div>
                </div>
              </div>
            </div>

            <div class="pools_info_d3" v-if="poolInfo.isUsageOfFundRaised">
              Usage of Fund Raised
            </div>
            <div class="pools_info_d4" v-if="poolInfo.isUsageOfFundRaised"></div>

            <div class="mobHide" style="height: 400px;"></div>
          </div>

        </div>
      </div>

      <el-dialog title="Contribute" :visible.sync="centerDialogVisible" center>
        <div class="vote_dialog">
          <div class="vote_balance">
            <div class="voer_lp_title">{{poolInfo.tokenName}} Balance: </div>
            <div>{{setDecimal(userBalance, 3, false, false)}}</div>
          </div>
          <div class="vote_balance">
            <div class="voer_lp_title">Lower limit: </div>
            <div>{{setDecimal(poolInfo.lowerLimit, 3, false, false)}}</div>
          </div>
          <div class="vote_balance">
            <div class="voer_lp_title">Upper limit: </div>
            <div>{{setDecimal(poolInfo.upperLimit, 3, false, false)}}</div>
          </div>
          <div class="vote_balance">
            <div class="voer_lp_title">Hard Cap: </div>
            <div>{{setDecimal(poolInfo.currentlyRaised, 3, false, false)}} / {{setDecimal(poolInfo.hardCap, 3, false, false)}}</div>
          </div>
          <div class="vote_value">
            <input v-model="voteValue" placeholder="Please enter the number of votes"></input>
            <div class="value_max" @click="setMax(true)">MAX</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeVoteDialog()">Cancel</el-button>
          <el-button type="primary" @click="userVoting()">Confirm</el-button>
        </span>
      </el-dialog>

    </div>
    <Bottom/>
  </div>
</template>

<script>
import Frames from "../../components/frames/Index"
import Bottom from "../../components/frames/Bottom";
import {mixin1} from "../../tools/mixins";
import store from "../../store";
import {changeDate, isNaN, setDecimal} from "../../tools/toolUtils";
import * as metaMaskTools from "../../tools/chain";
import {BigNumberDiv} from "../../tools";
import proiectsArrayInfo from "../../tools/proiectsArrayInfo";
import proiectsArrayIndex from "../../tools/proiectsArrayIndex";
import transfer from "../../tools/transfer";

export default {
  name: "Index",
  components: {Frames, Bottom},
  mixins: [mixin1],
  data() {
    return {
      accounts: '',
      centerDialogVisible: false,
      voteValue: null,
      userBalance: 0,
      detailLock: false,
      poolInfo: {
        indexNum: 0,
        isStart: false,
        isEnd: false,
        isJoin: false,
        authorizedLimit: 0,
        icon: '',
        poolName: '',
        poolAddress: '',
        tokenName: '',
        tokenAddress: '',
        hardCap: 0,
        currentlyRaised: 0,
        surplus: 0,
        myVote: 0,
        upperLimit: 0,
        lowerLimit: 0,
        startTime: 0,
        endTime : 0,
        isVision: false,
        isIntro: false,
        isTeam: false,
        isTokenPrice: false,
        isSocialMedia: false,
        isMilestone: false,
        isUsageOfFundRaised: false,
        desiredIdoPlatform: [
          {
            img: '',
            text: ''
          },
          {
            img: '',
            text: ''
          },
          {
            img: '',
            text: ''
          }
        ],
        vision: '',
        intro: '',
        team: '',
        tokenPrice: '',
        socialMedia: [
          {
            img: '',
            link: ''
          },
          {
            img: '',
            link: ''
          }
        ],
        milestone: {
          text1: '',
          text2: '',
          text3: '',
          text4: '',
          text5: '',
        }
      }
    };
  },
  watch: {},
  created() {
    this.initSetDetail()

    setTimeout(() => {
      this.onAccountsChanged();
    }, 1000)

    var that = this
    transfer.$on('tokenChainRefresh', function () {
      that.tokenChainRefresh()
    })
  },
  mounted() {
    window.scrollTo(0,0);

    this.checkPoolInfo()
    this.initPoolInfo()
  },
  beforeDestroy() {
  },
  methods: {
    tokenChainRefresh() {
      this.accounts = ''
      this.detailLock = false;
      this.initSetDetail();
      this.initPoolInfo();
    },
    checkPoolInfo() {
      if (localStorage.getItem('accounts')) {
        this.accounts = localStorage.getItem('accounts')
      }
      if (sessionStorage.getItem('poolInfo')) {
        this.poolInfo = JSON.parse(sessionStorage.getItem('poolInfo'))
        var tokenChainType = sessionStorage.getItem("tokenChainType") ? sessionStorage.getItem("tokenChainType") : 'BSC'
        var selIndex = parseInt(proiectsArrayIndex[tokenChainType][this.$route.query.projectName])
        if (parseInt(this.poolInfo.indexNum) !== parseInt(selIndex)) {
          this.poolInfo = proiectsArrayInfo[tokenChainType][parseInt(selIndex) - 1]
        }
      }
    },
    initSetDetail() {
      if (this.$route.query.projectName) {
        let tokenChainType = sessionStorage.getItem("tokenChainType") ? sessionStorage.getItem("tokenChainType") : 'BSC'
        let poolsIndex = parseInt(proiectsArrayIndex[tokenChainType][this.$route.query.projectName])
        if (poolsIndex <= proiectsArrayInfo[tokenChainType].length) {
          this.poolInfo = proiectsArrayInfo[tokenChainType][poolsIndex - 1]
        } else {
          this.toPool()
          if (parseInt(sessionStorage.getItem('urlIndex')) === 3) {
            this.$message.info('The chain does not have this pool. Please re select to view!')
          }
        }
      } else {
        this.toPool()
      }
    },
    onRefresh(accounts) {
      this.$store.commit('setState', {
        accounts
      });
      localStorage.setItem('accounts', accounts);
      if (localStorage.getItem('accounts')) {
        this.accounts = localStorage.getItem('accounts');
      } else {
        this.accounts = '';
      }
      this.detailLock = false;
      this.initPoolInfo()
    },
    refreshData(val) {
      if (val === undefined) {
        this.accounts = ''
      } else {
        this.accounts = val
      }
      this.detailLock = false;
      this.initPoolInfo();
    },
    setDecimal(numVal, numDecimalMax, roundUp, science) {
      return setDecimal(numVal, numDecimalMax, roundUp, science);
    },
    changeDate(val) {
      return changeDate(val);
    },
    toPool() {
      if (parseInt(sessionStorage.getItem('urlIndex')) === 3) {
        this.$router.push("/pools");
        sessionStorage.setItem('urlIndex', 2)
      }
    },
    async coinApproveVote(token, contract) {
      try {
        await metaMaskTools.coinApproveVote(token, contract, this.accounts).then(async (data) => {
          console.log(data)
          this.detailLock = false;
          this.initPoolInfo()
        });
      } catch (e) {
        console.log(e)
      }
    },
    async isStart(contract) {
      try {
        await metaMaskTools.isStart(contract).then(async (data) => {
          this.poolInfo.isStart = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async isEnd(contract) {
      try {
        await metaMaskTools.isEnd(contract).then(async (data) => {
          this.poolInfo.isEnd = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getAllowanceToken(contract) {
      try {
        await metaMaskTools.getAllowanceToken(this.poolInfo.tokenAddress, contract, this.accounts).then(async (data) => {
          this.poolInfo.authorizedLimit = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async queryMyNft(contract) {
      try {
        await metaMaskTools.queryMyNft(contract, this.accounts).then(async (data) => {
          this.poolInfo.isJoin = data[0];
          this.poolInfo.myVote = BigNumberDiv(data[1].voteCast, 1e18);
        });
      } catch (e) {
        console.log(e)
      }
    },
    async queryProjectInfo(contract) {
      try {
        await metaMaskTools.queryProjectInfo(contract).then(async (data) => {
          this.poolInfo.poolName = data[0];
          this.poolInfo.tokenAddress = data[1];
          this.poolInfo.hardCap = BigNumberDiv(data[2], 1e18);
          this.poolInfo.currentlyRaised = BigNumberDiv(data[3], 1e18);
          this.poolInfo.surplus = BigNumberDiv(data[4], 1e18);
          this.poolInfo.lowerLimit = BigNumberDiv(data[5], 1e18);
          this.poolInfo.upperLimit = BigNumberDiv(data[6], 1e18);
          // this.poolInfo.endTime = parseInt(data[7]);
        });
        // await metaMaskTools.getStartTime(contract).then(async (data) => {
        //   this.poolInfo.startTime = parseInt(data);
        // });
      } catch (e) {
        console.log(e)
      }
    },
    initPoolInfo() {
      if (this.detailLock) {
        return;
      } else {
        this.detailLock = true;
      }
      store.dispatch("getHomeData");
      if (this.poolInfo.poolAddress) {
        this.isStart(this.poolInfo.poolAddress);
        this.isEnd(this.poolInfo.poolAddress);
        this.queryProjectInfo(this.poolInfo.poolAddress);
        if (!this.accounts) {
          this.poolInfo.authorizedLimit = 0;
          this.poolInfo.myVote = 0;
          this.poolInfo.isJoin = false;
        } else {
          this.getAllowanceToken(this.poolInfo.poolAddress);
          this.queryMyNft(this.poolInfo.poolAddress);
        }
      }
      setTimeout(() => {
        this.detailLock = false;
        this.initPoolInfo()
      }, 5000)
    },
    closeVoteDialog() {
      this.voteValue = null
      this.centerDialogVisible = false;
    },
    openVoteDialog(token) {
      this.centerDialogVisible = true;
      this.getLpBalanceOf(token)
    },
    async getLpBalanceOf(token) {
      try {
        await metaMaskTools.getLpBalanceOf(token, this.accounts).then(async (data) => {
          this.userBalance = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    setMax(isSet) {
      let maxNum = 0
      if (this.userBalance < this.poolInfo.lowerLimit) {
        this.$message.warning('Your quota is insufficient!');
        return
      }
      let num = this.poolInfo.upperLimit - this.poolInfo.myVote
      maxNum = this.userBalance - num > 0 ? num : this.userBalance
      maxNum = maxNum - this.poolInfo.surplus > 0 ? this.poolInfo.surplus : maxNum
      if (isSet) {
        this.voteValue = maxNum
        return
      }
      return maxNum
    },
    async userVoting() {
      try {
        if (!this.poolInfo.isStart) {
          this.$message.warning('The pool hasn\'t started!');
          return
        }
        if (this.poolInfo.isEnd) {
          this.$message.warning('Voting is over!');
          return
        }
        if (this.poolInfo.isJoin) {
          this.$message.warning('You cannot vote repeatedly!');
          return
        }
        if (this.voteValue === null || this.voteValue === 0 || this.voteValue === '' || isNaN(this.voteValue)) {
          this.$message.warning('Please enter the number of votes!');
          return
        }
        this.voteValue = parseFloat(this.voteValue)
        if ((this.voteValue + parseFloat(this.poolInfo.myVote)) < this.poolInfo.lowerLimit) {
          this.$message.warning('The number of votes cannot be less than the minimum!');
          return
        }
        let maxValue = parseFloat(this.setMax(false));
        if (this.voteValue > maxValue) {
          this.$message.warning('Exceeds the current maximum number of votes!');
          return
        }
        await metaMaskTools.userVoting(this.poolInfo.poolAddress, this.accounts, this.voteValue).then(async (data) => {
          console.log(data)
          this.detailLock = false;
          this.initPoolInfo()
          this.closeVoteDialog();
        });
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="less">
  .el-dialog {
    width: 500px !important;
    background: #020202;
    margin-top: 25vh !important;
    border: 2px solid #e5c50a !important;
    border-radius: 5px !important;
    box-shadow: 3px 3px 3px rgb(255 255 255 / 30%) !important;
    box-sizing: border-box;
  }
  .el-dialog__title {
    line-height: 24px !important;
    font-size: 22px !important;
    color: #e5c50a !important;
  }
  .el-button {
    color: #e5c50a !important;
    font-weight: 600 !important;
  }
  .el-button:hover {
    color: black !important;
  }
  .el-button--primary {
    color: #FFF !important;
    background-color: #e5c50a !important;
    border-color: #e5c50a !important;
  }
  .el-button--primary:hover {
    background-color: #e5c50a !important;
    border-color: #e5c50a !important;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #FFF !important;
  }
  .el-dialog__headerbtn .el-dialog__close:hover {
    color: #e5c50a !important;
  }
</style>

<style scoped lang="less">
  .pools_page {
    width: 100%;
    height: auto;

    .huang {
      color: #FEE108 !important;
    }

    .pools_all::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .pools_all {
      //position: absolute;
      //top: 100px;
      //bottom: 305px;
      width: inherit;
      min-width: 1080px;
      overflow-y: auto;
      background-color: black;

      .pools_top {
        display: flex;

        .pool_text {
          color: white;
          margin: 50px 60px;

          .pool_text_a {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 45px;
            line-height: 50px;
          }

          .pool_text_b {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 45px;
            line-height: 50px;
            color: #FEE108;
          }

          .pool_text_c {
            margin: 15px 0;
            line-height: 30px;
            font-size: 20px;
          }

          .pool_text_d {
            max-width: 610px;
            color: black;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .d_anniu_a {
              cursor: pointer;
              width: 190px;
              height: 45px;
              text-align: center;
              line-height: 45px;
              background: #3CD63C;
              border: 1px solid #83DB5C;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }

            .d_anniu_b {
              cursor: pointer;
              width: 190px;
              height: 45px;
              text-align: center;
              line-height: 45px;
              background: #FEE108;
              border: 1px solid #FEE108;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }

            .d_anniu_c {
              cursor: pointer;
              width: 190px;
              height: 45px;
              text-align: center;
              line-height: 45px;
              background: #40EFE5;
              border: 1px solid #40EFE5;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }
          }

          .pool_text_e {
            max-width: 610px;

            .e_num {
              color: #FEE108;
              font-size: 30px;
              font-weight: 600;
              margin-top: 15px;
            }

            .e_text {
              font-weight: 600;
            }

            display: flex;
            align-items: center;
            justify-content: space-around;
            text-align: center;

            .e_kuang_a {
              width: 190px;
              height: 100px;
              margin: 30px 10px 0 0;
              background: #131313;
              border: 4px solid #FEE108;
              box-sizing: border-box;
              border-radius: 13px;
            }

            .e_kuang_b {
              width: 190px;
              height: 100px;
              margin: 30px 10px 0 0;
              background: #131313;
              border: 4px solid #FEE108;
              box-sizing: border-box;
              border-radius: 13px;
            }

            .e_kuang_c {
              width: 190px;
              height: 100px;
              margin: 30px 0 0 0;
              background: #131313;
              border: 4px solid #FEE108;
              box-sizing: border-box;
              border-radius: 13px;
            }
          }
        }

        .pool_img {
          img {
            margin: 40px;
            width: 390px;
          }
        }
      }

      .pools_info {
        zoom: 0.9;
        margin: 0 100px;
        color: white;

        .info_pools {
          width: 100%;

          .pool_data {
            height: 280px;
            background: #000000;
            border: 3px solid #FEE108;
            box-sizing: border-box;
            box-shadow: 0px 0px 50px #000000;
            border-radius: 15px;
            margin: 20px 50px;

            .data_top {
              height: 110px;

              > div {
                > div:nth-of-type(1) {
                  margin-bottom: 5px;
                }
              }

              display: flex;
              justify-content: space-around;
              align-items: center;

              .data_logo {
                width: 60px;
                height: 60px;
                background: #000000;
                border-radius: 50%;
                box-shadow: 0px 0px 10px #7EFD00;

                img {
                  width: 60px;
                  border-radius: 50%;
                  aspect-ratio: 1/1;
                  margin: 0;
                }
              }

              .data_title {
                font-weight: 600;
                .name {
                  font-size: 24px;
                }
                .biaoji {
                  color: #6C6C6C;
                  font-size: 15px;
                }
              }

              .data_info {
                text-align: center;
                font-weight: 600;
                .da {
                  font-size: 24px;
                }
                .xiao {
                  font-size: 15px;
                }
              }

              .data_time {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                flex-wrap: nowrap;
                font-weight: 600;
                .end_time {
                }
                .detail {
                  cursor: pointer;
                  margin: 10px 0 0 0;
                }
              }
            }

            .data_desired {
              border-top: 3px solid #FEE108;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              height: 170px;
              box-sizing: border-box;
              .data_desired_d1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 26px;
                line-height: 35px;
                color: #FFFFFF;
                margin: 0 10px 0 30px;
                display: flex;
                /*white-space: nowrap;*/
              }
              .data_desired_tba {
                display: flex;
                flex-direction: row;
                @media screen and (max-width: 768px) {
                  flex-direction: column;
                }
                justify-content: space-evenly;
              }
              .data_desired_d2 {
                width: 200px;
                height: 90px;
                border: 4px solid #FEE108;
                box-sizing: border-box;
                border-radius: 13px;
                margin: 0 6px;
                font-size: 17px;
                line-height: 20px;
                color: #FFFFFF;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .placeholder {
                  font-size: 64px;
                  line-height: 75px;
                  color: #FFFFFF;
                }
              }
              .data_desired_d3 {
                width: 150px;
                height: 40px;
              }
              .data_desired_d4{
                cursor: pointer;
                width: 200px;
                height: 55px;
                background: #FEE108;
                border: 1px solid #FEE108;
                box-sizing: border-box;
                border-radius: 10px;
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 28px;
                color: #000000;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 20px;
              }

              .vote {
                background: #FEE108;
                border: 1px solid #FEE108;
              }
              .approve {
                background: white;
                border: 1px solid white;
              }

              .data_desired_d5 {
                display: flex;
                justify-content: space-around;
                align-items: center;
              }

              .data_desired_d6 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 47px;
                color: #FEE108;
                margin: 0 10px 0 30px;
              }
            }
          }
        }

        .pools_info_d1 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 20px 50px;

          > img:nth-of-type(1) {
            width: 39px;
            height: 39px;
            margin-right: 8px;
          }

          > img:nth-of-type(2) {
            width: 30px;
            height: 20px;
            margin-right: 8px;
          }

          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 37px;
          line-height: 43px;
          text-align: center;
          color: #FFFFFF;
        }

        .pools_info_d2 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 57px;
          line-height: 67px;
          text-align: center;
          color: #FFFFFF;
          margin: 45px 50px 30px 50px;
        }

        .pools_info_d3 {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 50px;
          line-height: 59px;
          text-align: left;
          color: #FFFFFF;
          margin: 80px 0 0;
        }

        .pools_info_d4 {
          width: 47px;
          height: 4px;
          background: #FEE108;
          margin: 5px 0 20px;
        }

        .pools_info_d5 {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 33px;
          color: #fff;
        }

        .pools_info_d6 {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          > div {
            margin-right: 15px;
            width: 53px;
            height: 53px;
            background: #FEE108;
            border-radius: 27px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            a {
              img {
                width: 35px;
                height: 35px;
              }

              .guanwang {
                width: 53px;
                height: 53px;
                border-radius: 50%;
                aspect-ratio: 1/1;
              }
            }
          }
        }

        .pools_info_d7 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 22px;
          .pools_info_d7_d1 {
            width: 20px;
            height: 20px;
            background: #24DE4D;
            border-radius: 10px;
            margin-right: 15px;
          }
          .pools_info_d7_d2 {
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 23px;
            line-height: 27px;
            color: #FFFFFF;
          }
          .pools_info_d7_d3 {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 23px;
            line-height: 27px;
            color: #FFFFFF;
          }
          .pools_info_d7_d4{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }
        }
      }

      .vote_dialog {
        .vote_balance {
          .voer_lp_title {
            width: 150px;
            text-align: right;
          }
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 18px;
          color: white;
          margin: 10px 0 20px 0;
        }
        .vote_value {
          display: flex;
          align-items: center;
          justify-content: space-between;
          input {
            font-size: 18px;
            font-weight: 600;
            line-height: 36px;
            color: black;
            width: calc(100% - 80px);
            height: 30px;
            border-radius: 8px;
          }
          .value_max {
            cursor: pointer;
            width: 60px;
            height: 35px;
            border-radius: 8px;
            text-align: center;
            line-height: 35px;
            color: white;
            background-color: #e5c50a;
          }
        }
      }

    }

    .yellow {
      color: #FEE108;
    }

    .trr {
      text-align: right;
    }

    .clear{
      height: auto;
      overflow: hidden;
    }
  }

  @import '../../global.less';
</style>
