// debug  true:测试  false:正式, "54.254.254.47"
// const devUrl = ["localhost", "127.0.0.1", "192.168.2.29"];
const devUrl = ["192.168.2.29"];

const getEnv = () => {
    const link = window.location.href;
    let isDebug = false;
    for (let k in devUrl) {
        if (link.indexOf(devUrl[k]) !== -1) {
            isDebug = true;
        }
    }
    return isDebug;
}

let proiectsArrayIndex = getEnv() ?
    {
        // 测试
        'BSC': {
            'PROJECT_NAME': 1,
            'PROJECT_NAME#A': 2,
            'CHEERS': 3
        },
        'ETH': {},
        'AVAX': {
            'CHEERS_AVAX': 1
        },
        'POLYGON': {}
    }
    :
    {
    // 正式
        'BSC': {
            'Coxena': 1,
            'Coxena%20%232': 2,
        },
        'ETH': {},
        'AVAX': {},
        'POLYGON': {}
    };

export default proiectsArrayIndex;
