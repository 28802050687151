<template>
  <div class="pools_page">
    <Frames/>
    <div class="pools_all">
      <div class="pools_top">
        <div class="pool_text">
          <div class="pool_text_a">TrustFi Booster</div>
          <div class="pool_text_b"> Built on Web 3.0</div>
          <div class="pool_text_c">TrustFi focuses on early crypto assets issuance, liquidity management, community
            activities and DAO governance to unlock the potential of DeFi
          </div>
          <div class="pool_text_d">
            <buy-tfi-btn />
            <div class="d_anniu_b" @click="openUrl('https://docs.google.com/forms/d/e/1FAIpQLSca1CqJqUem0Wi_WPMYWaXMWwan8iB7ygp87wOsuoM1DGwtlg/viewform')">Apply for Boosting</div>
            <div class="d_anniu_c" @click="openUrl('https://solidity.finance/audits/TrustFiBooster/')">Audit Report</div>
          </div>
          <div class="pool_text_e">
            <div class="e_kuang_a">
              <div class="e_num">{{ trustFiPoolArr.length }}</div>
              <div class="e_text">Raised Project</div>
            </div>
            <div class="e_kuang_b">
              <div class="e_num">
                $ {{ parseFloat($store.state.systemInfo.totalValueLocked) > 10000 ? setTenThousand($store.state.systemInfo.totalValueLocked) : $store.state.systemInfo.totalValueLocked }}
                {{parseFloat($store.state.systemInfo.totalValueLocked) > 10000 ? 'W' : ''}}</div>
              <div class="e_text">Total Value Locked</div>
            </div>
            <div class="e_kuang_c">
              <div class="e_num">{{ $store.state.systemInfo.totalParticipants }}</div>
              <div class="e_text">Total Participants</div>
            </div>
          </div>
        </div>
        <div class="pool_img mobHide">
          <img src="../../assets/images/Group.png">
        </div>
      </div>
      <div class="pools_info">
        <div class="info_tile">
          What we can do:
        </div>

        <div class="info_text">
          <div class="info_text_a">Fundraising in community-driven way, FCFS + Limited NFT Reward</div>
          <div class="info_text_a">Share and utilize TrustFi’s infrastructure, market exposure, and large network</div>
          <div class="info_text_a">Recommend your projects to LaunchPads for official launch</div>
          <div class="info_text_a">Recommend your projects to VCs and KOLs for investment</div>
          <div class="info_text_a">TrustFi gets onboard as a long-term backer and advisor</div>
        </div>

        <div class="info_kong_jian">
          <div class="jian_anniu">
            <div :class="selectAnniu === 0 ? 'anniu_live anniu_select' : 'anniu_live'" @click="setLive(0)">Live</div>
            <div :class="selectAnniu !== 0 ? 'anniu_finished anniu_select' : 'anniu_finished'" @click="setLive(1)">Finished</div>
          </div>
        </div>

        <div class="info_pools">

          <div class="pool_data" v-if="trustFiPoolArr.length > 0" v-for="(pool, index) in trustFiPoolArr" :key="index" v-show="selectAnniu === 0 ? !pool.isEnd : pool.isEnd">
            <div class="data_top">
              <div class="data_logo">
                <img :src="pool.icon"/>
              </div>
              <div class="data_title">
                <div class="name">{{pool.poolName}}</div>
                <div class="biaoji">Booster</div>
              </div>
              <div class="data_info">
                <div class="da yellow">{{pool.tokenName}}</div>
                <div class="xiao">Sponsor</div>
              </div>
              <div class="data_info">
                <div class="da yellow">{{pool.hardCap}}</div>
                <div class="xiao">Hard Cap</div>
              </div>
              <div class="data_info">
                <div class="da yellow">{{pool.currentlyRaised}}</div>
                <div class="xiao">Currently Raised</div>
              </div>
              <div class="data_info">
                <div class="da yellow">{{pool.myVote}}</div>
                <div class="xiao">My Investment</div>
              </div>
              <div class="data_time">
<!--                <div class="end_time">Start Time : {{changeDate(pool.startTime)}}</div>-->
<!--                <div class="end_time">End Time : {{changeDate(pool.endTime)}}</div>-->
                <div class="end_time">Start Time : {{pool.startTime}}</div>
                <div class="end_time">End Time : {{pool.endTime}}</div>
                <div style="cursor: pointer" class="detail yellow trr" @click="toDetail(pool)">
                  DETAIL →
                </div>
              </div>
            </div>
            <div class="data_desired">
              <div class="data_desired_d1">Estimated IDO Platform:</div>
              <div class="data_desired_d5">

                <div v-if="pool.desiredIdoPlatform.length > 0" class="data_desired_d2" v-for="(obj, i) in pool.desiredIdoPlatform" :key="i">
                  <img class="data_desired_d3" :src="obj.img" />
                  <div>{{obj.text}}</div>
                </div>

                <div class="data_desired_tba" v-if="pool.desiredIdoPlatform.length === 0">
                  <div class="data_desired_d2">
                    <div class="placeholder">TBA</div>
                  </div>
                  <div class="data_desired_d2">
                    <div class="placeholder">TBA</div>
                  </div>
                  <div class="data_desired_d2">
                    <div class="placeholder">TBA</div>
                  </div>
                </div>

              </div>
              <div class="data_desired_d4 vote" @click="openVoteDialog(index, pool.tokenAddress)" v-if="(parseFloat(pool.authorizedLimit) > 0) && !pool.isEnd">
                <div>Contribute</div>
              </div>
              <div class="data_desired_d4 approve" @click="coinApproveVote(pool.tokenAddress, pool.poolAddress)" v-if="(parseFloat(pool.authorizedLimit) === 0) && !pool.isEnd">
                <div>Approve</div>
              </div>
              <div class="data_desired_d4 approve" style="cursor: not-allowed" v-if="pool.isEnd">
                <div>End</div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <el-dialog title="Contribute" :visible.sync="centerDialogVisible" v-if="trustFiPoolArr.length > 0" center>
        <div class="vote_dialog">
          <div class="vote_balance">
            <div class="voer_lp_title">{{trustFiPoolArr[poolIndex].tokenName}} Balance: </div>
            <div>{{setDecimal(userBalance, 3, false, false)}}</div>
          </div>
          <div class="vote_balance">
            <div class="voer_lp_title">Lower limit: </div>
            <div>{{setDecimal(trustFiPoolArr[poolIndex].lowerLimit, 3, false, false)}}</div>
          </div>
          <div class="vote_balance">
            <div class="voer_lp_title">Upper limit: </div>
            <div>{{setDecimal(trustFiPoolArr[poolIndex].upperLimit, 3, false, false)}}</div>
          </div>
          <div class="vote_balance">
            <div class="voer_lp_title">Hard Cap: </div>
            <div>{{setDecimal(trustFiPoolArr[poolIndex].currentlyRaised, 3, false, false)}} / {{setDecimal(trustFiPoolArr[poolIndex].hardCap, 3, false, false)}}</div>
          </div>
          <div class="vote_value">
            <input v-model="voteValue" placeholder="Please enter the number of votes"></input>
            <div class="value_max" @click="setMax(true)">MAX</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeVoteDialog()">Cancel</el-button>
          <el-button type="primary" @click="userVoting()">Confirm</el-button>
        </span>
      </el-dialog>

    </div>
    <footer-v3 />
  </div>
</template>

<script>
import Frames from "../../components/frames/Index"
import * as metaMaskTools from "../../tools/chain";
import {mixin1} from "../../tools/mixins";
import {
  BigNumber,
  BigNumberStr,
  BigNumberMul,
  BigNumberDiv,
  BigNumberAdd,
  BigNumberSub,
  setUnit,
  cutAccount
} from '../../tools/index';
import {setDecimal, isNaN, changeDate} from "../../tools/toolUtils";
import store from "../../store";
import proiectsArrayInfo from "../../tools/proiectsArrayInfo";
import transfer from "../../tools/transfer";
import BuyTfiBtn from "../../components/frames/BuyTfiBtn";
import FooterV3 from "../../components/frames/FooterV3";

export default {
  name: "Index",
  components: {FooterV3, BuyTfiBtn, Frames},
  mixins: [mixin1],
  data() {
    return {
      accounts: '',
      selectAnniu: 0,
      centerDialogVisible: false,
      voteValue: null,
      poolIndex: 0,
      userBalance: 0,
      poolsLock: false,
      trustFiPoolArr: []
    };
  },
  watch: {},
  created() {
    this.selectChainType()

    setTimeout(() => {
      this.onAccountsChanged();
    }, 1000)

    var that = this
    transfer.$on('tokenChainRefresh', function () {
      that.tokenChainRefresh()
    })
  },
  mounted() {
    if (sessionStorage.getItem('selectAnniu')) {
      this.selectAnniu = parseInt(sessionStorage.getItem('selectAnniu'))
    }
    if (localStorage.getItem('accounts')) {
      this.accounts = localStorage.getItem('accounts');
    }
    this.initPools();

    setTimeout(()=> {
      this.queryPoolIsEnd()
    }, 1500)
  },
  beforeDestroy() {
  },
  methods: {
    openUrl(url) {
      window.open(url)
    },
    selectChainType() {
      let tokenChainType = sessionStorage.getItem("tokenChainType") ? sessionStorage.getItem("tokenChainType") : 'BSC'
      this.trustFiPoolArr = proiectsArrayInfo[tokenChainType]
    },
    tokenChainRefresh() {
      this.accounts = ''
      this.poolsLock = false;
      this.selectChainType()
      this.initPools();
    },
    onRefresh(accounts) {
      this.$store.commit('setState', {
        accounts
      });
      localStorage.setItem('accounts', accounts);
      if (localStorage.getItem('accounts')) {
        this.accounts = localStorage.getItem('accounts');
      } else {
        this.accounts = '';
      }
      this.poolsLock = false;
      this.initPools();
    },
    refreshData(val) {
      if (val === undefined) {
        this.accounts = ''
      } else {
        this.accounts = val
      }
      this.poolsLock = false;
      this.initPools();
    },
    cutAccount(account, num) {
      return cutAccount(account, num);
    },
    setDecimal(numVal, numDecimalMax, roundUp, science) {
      return setDecimal(numVal, numDecimalMax, roundUp, science);
    },
    changeDate(val) {
      return changeDate(val);
    },
    setTenThousand(val) {
      return (parseFloat(val) / 10000);
    },
    queryPoolIsEnd() {
      for (let i = 0; i < this.trustFiPoolArr.length; i++) {
        this.isStart(i, this.trustFiPoolArr[i].poolAddress);
        this.isEnd(i, this.trustFiPoolArr[i].poolAddress);
      }
      store.dispatch("getHomeData");
      setTimeout(()=> {
        this.queryPoolIsEnd()
      }, 1500)
    },
    setLive(val) {
      this.selectAnniu = parseInt(val)
      sessionStorage.setItem('selectAnniu', val)
    },
    toDetail(obj) {
      sessionStorage.setItem('urlIndex', 3)
      sessionStorage.setItem('poolInfo', JSON.stringify(obj));
      this.$router.push({
        path: "/pools/detail",
        query: {
          projectName: encodeURIComponent(obj.poolName)
        }
      });
    },
    async isStart(index, contract) {
      try {
        await metaMaskTools.isStart(contract).then(async (data) => {
          this.trustFiPoolArr[index].isStart = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async isEnd(index, contract) {
      try {
        await metaMaskTools.isEnd(contract).then(async (data) => {
          this.trustFiPoolArr[index].isEnd = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getAllowanceToken(index, token, contract) {
      try {
        await metaMaskTools.getAllowanceToken(token, contract, this.accounts).then(async (data) => {
          this.trustFiPoolArr[index].authorizedLimit = parseFloat(data)
        });
      } catch (e) {
        console.log(e)
      }
    },
    async queryMyNft(index, contract) {
      try {
        await metaMaskTools.queryMyNft(contract, this.accounts).then(async (data) => {
          this.trustFiPoolArr[index].isJoin = data[0];
          this.trustFiPoolArr[index].myVote = BigNumberDiv(data[1].voteCast, 1e18);
        });
      } catch (e) {
        console.log(e)
      }
    },
    async queryProjectInfo(index, contract) {
      try {
        await metaMaskTools.queryProjectInfo(contract).then(async (data) => {
          this.trustFiPoolArr[index].poolName = data[0];
          this.trustFiPoolArr[index].tokenAddress = data[1];
          this.trustFiPoolArr[index].hardCap = BigNumberDiv(data[2], 1e18);
          this.trustFiPoolArr[index].currentlyRaised = BigNumberDiv(data[3], 1e18);
          this.trustFiPoolArr[index].surplus = BigNumberDiv(data[4], 1e18);
          this.trustFiPoolArr[index].lowerLimit = BigNumberDiv(data[5], 1e18);
          this.trustFiPoolArr[index].upperLimit = BigNumberDiv(data[6], 1e18);
          // this.trustFiPoolArr[index].endTime = parseInt(data[7]);
        });
        // await metaMaskTools.getStartTime(contract).then(async (data) => {
        //   this.trustFiPoolArr[index].startTime = parseInt(data);
        // });
      } catch (e) {
        console.log(e)
      }
    },
    initPools() {
      store.dispatch("getHomeData");
      if (localStorage.getItem('accounts')) {
        this.accounts = localStorage.getItem('accounts');
      } else {
        this.accounts = '';
      }
      if (!this.poolsLock) {
        this.poolsLock = true;
        for (let i = 0; i < this.trustFiPoolArr.length; i++) {
          this.isStart(i, this.trustFiPoolArr[i].poolAddress);
          this.isEnd(i, this.trustFiPoolArr[i].poolAddress);
          this.queryProjectInfo(i, this.trustFiPoolArr[i].poolAddress);
          if (!this.accounts) {
            this.trustFiPoolArr[i].authorizedLimit = 0;
            this.trustFiPoolArr[i].myVote = 0;
            this.trustFiPoolArr[i].isJoin = false;
          } else {
            this.getAllowanceToken(i, this.trustFiPoolArr[i].tokenAddress, this.trustFiPoolArr[i].poolAddress);
            this.queryMyNft(i, this.trustFiPoolArr[i].poolAddress);
          }
        }
        setTimeout(() => {
          this.poolsLock = false;
          this.initPools()
        }, 15000)
      }
    },
    async coinApproveVote(token, contract) {
      try {
        if (!this.accounts) {
          this.$message.warning('Please connect your wallet first!');
          return;
        }
        await metaMaskTools.coinApproveVote(token, contract, this.accounts).then(async (data) => {
          console.log(data)
          this.poolsLock = false;
          this.initPools();
        });
      } catch (e) {
        console.log(e)
      }
    },
    async getLpBalanceOf(token) {
      try {
        await metaMaskTools.getLpBalanceOf(token, this.accounts).then(async (data) => {
          this.userBalance = data
        });
      } catch (e) {
        console.log(e)
      }
    },
    closeVoteDialog() {
      this.voteValue = null
      this.centerDialogVisible = false;
    },
    openVoteDialog(index, token) {
      this.centerDialogVisible = true;
      this.poolIndex = index;
      this.getLpBalanceOf(token)
    },
    setMax(isSet) {
      let maxNum = 0
      if (this.userBalance < this.trustFiPoolArr[this.poolIndex].lowerLimit) {
        this.$message.warning('Your quota is insufficient!');
        return
      }
      let num = this.trustFiPoolArr[this.poolIndex].upperLimit - this.trustFiPoolArr[this.poolIndex].myVote
      maxNum = this.userBalance - num > 0 ? num : this.userBalance
      maxNum = maxNum - this.trustFiPoolArr[this.poolIndex].surplus > 0 ? this.trustFiPoolArr[this.poolIndex].surplus : maxNum
      if (isSet) {
        this.voteValue = maxNum
        return
      }
      return maxNum
    },
    async userVoting() {
      try {
        if (!this.trustFiPoolArr[this.poolIndex].isStart) {
          this.$message.warning('The pool hasn\'t started!');
          return
        }
        if (this.trustFiPoolArr[this.poolIndex].isEnd) {
          this.$message.warning('Voting is over!');
          return
        }
        if (this.trustFiPoolArr[this.poolIndex].isJoin) {
          this.$message.warning('You cannot vote repeatedly!');
          return
        }
        if (this.voteValue === null || this.voteValue === 0 || this.voteValue === '' || isNaN(this.voteValue)) {
          this.$message.warning('Please enter the number of votes!');
          return
        }
        this.voteValue = parseFloat(this.voteValue)
        if ((this.voteValue + parseFloat(this.trustFiPoolArr[this.poolIndex].myVote)) < this.trustFiPoolArr[this.poolIndex].lowerLimit) {
          this.$message.warning('The number of votes cannot be less than the minimum!');
          return
        }
        let maxValue = parseFloat(this.setMax(false));
        if (this.voteValue > maxValue) {
          this.$message.warning('Exceeds the current maximum number of votes!');
          return
        }
        await metaMaskTools.userVoting(this.trustFiPoolArr[this.poolIndex].poolAddress, this.accounts, this.voteValue).then(async (data) => {
          console.log(data)
          this.poolsLock = false;
          this.initPools();
          this.closeVoteDialog();
        });
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="less">
  .el-dialog {
    width: 500px !important;
    background: #020202;
    margin-top: 25vh !important;
    border: 2px solid #e5c50a !important;
    border-radius: 5px !important;
    box-shadow: 3px 3px 3px rgb(255 255 255 / 30%) !important;
    box-sizing: border-box;
  }
  .el-dialog__title {
    line-height: 24px !important;
    font-size: 22px !important;
    color: #e5c50a !important;
  }
  .el-button {
    color: #e5c50a !important;
    font-weight: 600 !important;
  }
  .el-button:hover {
    color: black !important;
  }
  .el-button--primary {
    color: #FFF !important;
    background-color: #e5c50a !important;
    border-color: #e5c50a !important;
  }
  .el-button--primary:hover {
    background-color: #e5c50a !important;
    border-color: #e5c50a !important;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #FFF !important;
  }
  .el-dialog__headerbtn .el-dialog__close:hover {
    color: #e5c50a !important;
  }
</style>

<style scoped lang="less">
  .pools_page {
    width: 100%;
    height: auto;

    .huang {
      color: #FEE108 !important;
    }

    .pools_all::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .pools_all {
      //position: absolute;
      //top: 100px;
      //bottom: 305px;
      width: inherit;
      min-width: 1080px;
      overflow-y: auto;
      background-color: black;

      .pools_top {
        display: flex;
        zoom: 0.9;
        margin: 0 100px;

        .pool_text {
          color: white;
          margin: 50px 60px;

          .pool_text_a {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 45px;
            line-height: 50px;
          }

          .pool_text_b {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 45px;
            line-height: 50px;
            color: #FEE108;
          }

          .pool_text_c {
            margin: 15px 0;
            line-height: 30px;
            font-size: 20px;
          }

          .pool_text_d {
            max-width: 610px;
            color: black;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .d_anniu_a {
              cursor: pointer;
              width: 190px;
              height: 45px;
              text-align: center;
              line-height: 45px;
              background: #3CD63C;
              border: 1px solid #83DB5C;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }

            .d_anniu_b {
              cursor: pointer;
              width: 190px;
              height: 45px;
              text-align: center;
              line-height: 45px;
              background: #FEE108;
              border: 1px solid #FEE108;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }

            .d_anniu_c {
              cursor: pointer;
              width: 190px;
              height: 45px;
              text-align: center;
              line-height: 45px;
              background: #40EFE5;
              border: 1px solid #40EFE5;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
            }
          }

          .pool_text_e {
            max-width: 610px;

            .e_num {
              color: #FEE108;
              font-size: 30px;
              font-weight: 600;
              margin-top: 15px;
            }

            .e_text {
              font-weight: 600;
            }

            display: flex;
            align-items: center;
            justify-content: space-around;
            text-align: center;

            .e_kuang_a {
              width: 190px;
              height: 100px;
              margin: 30px 10px 0 0;
              background: #131313;
              border: 4px solid #FEE108;
              box-sizing: border-box;
              border-radius: 13px;
            }

            .e_kuang_b {
              width: 190px;
              height: 100px;
              margin: 30px 10px 0 0;
              background: #131313;
              border: 4px solid #FEE108;
              box-sizing: border-box;
              border-radius: 13px;
            }

            .e_kuang_c {
              width: 190px;
              height: 100px;
              margin: 30px 0 0 0;
              background: #131313;
              border: 4px solid #FEE108;
              box-sizing: border-box;
              border-radius: 13px;
            }
          }
        }

        .pool_img {
          img {
            margin: 40px;
            width: 390px;
          }
        }
      }

      .pools_info {
        zoom: 0.9;
        margin: 0 100px;
        border-top: 4px solid #FEE108;
        color: white;

        .info_tile {
          font-size: 45px;
          font-weight: 600;
          text-align: center;
          margin: 50px 0 20px 0;
        }

        .info_text {
          text-align: center;
          font-size: 20px;
          line-height: 30px;
          font-family: Roboto;
          font-style: normal;
          text-align: center;

          .info_text_a {
            margin: 10px 50px;
          }

          .info_text_b {
            margin: 30px 50px;
          }
        }

        .info_kong_jian {
          height: 50px;
          margin: 45px 0;

          .jian_anniu {
            text-align: center;
            line-height: 42px;
            font-weight: 600;
            color: white;
            float: right;
            display: flex;
            justify-content: space-evenly;
            width: 420px;
            margin: 10px;

            .anniu_select {
              color: black;
              background: #7FFF00 !important;
              border: 1px solid #7FFF00 !important;
              box-sizing: border-box !important;
              box-shadow: 0px 0px 18px #8BCC49 !important;
            }

            .anniu_live {
              cursor: pointer;
              width: 150px;
              height: 42px;
              background: #717171;
              border: 1px solid #6C6C6C;
              box-sizing: border-box;
              box-shadow: 0px 0px 18px #000000;
              border-radius: 10px;
            }

            .anniu_finished {
              cursor: pointer;
              width: 150px;
              height: 42px;
              background: #717171;
              border: 1px solid #6C6C6C;
              box-sizing: border-box;
              box-shadow: 0px 0px 18px #000000;
              border-radius: 10px;
            }
          }
        }

        .info_pools {
          width: 100%;

          .pool_data {
            height: 280px;
            background: #000000;
            border: 3px solid #FEE108;
            box-sizing: border-box;
            box-shadow: 0px 0px 50px #000000;
            border-radius: 15px;
            margin: 20px 50px 50px 50px;

            .data_top {
              height: 110px;
              > div {
                > div:nth-of-type(1) {
                  margin-bottom: 5px;
                }
              }

              display: flex;
              justify-content: space-around;
              align-items: center;

              .data_logo {
                width: 60px;
                height: 60px;
                background: #000000;
                border-radius: 50%;
                box-shadow: 0px 0px 10px #7EFD00;

                img {
                  width: 60px;
                  border-radius: 50%;
                  aspect-ratio: 1/1;
                  margin: 0;
                }
              }

              .data_title {
                font-weight: 600;
                .name {
                  font-size: 24px;
                }
                .biaoji {
                  color: #6C6C6C;
                  font-size: 15px;
                }
              }

              .data_info {
                text-align: center;
                font-weight: 600;
                .da {
                  font-size: 24px;
                }
                .xiao {
                  font-size: 15px;
                }
              }

              .data_time {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                flex-wrap: nowrap;
                font-weight: 600;
                .end_time {
                }
                .detail {
                  cursor: pointer;
                  margin: 10px 0 0 0;
                }
              }
            }

            .data_desired {
              border-top: 3px solid #FEE108;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              height: 170px;
              box-sizing: border-box;
              .data_desired_d1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 26px;
                line-height: 35px;
                color: #FFFFFF;
                margin: 0 10px 0 30px;
                display: flex;
                /*white-space: nowrap;*/
              }
              .data_desired_tba {
                display: flex;
                flex-direction: row;
                @media screen and (max-width: 768px) {
                  flex-direction: column;
                }
                justify-content: space-evenly;
              }
              .data_desired_d2 {
                width: 200px;
                height: 90px;
                border: 4px solid #FEE108;
                box-sizing: border-box;
                border-radius: 13px;
                margin: 0 6px;
                font-size: 17px;
                line-height: 20px;
                color: #FFFFFF;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .placeholder {
                  font-size: 64px;
                  line-height: 75px;
                  color: #FFFFFF;
                }
              }
              .data_desired_d3 {
                width: 150px;
                height: 40px;
              }
              .data_desired_d4{
                cursor: pointer;
                width: 200px;
                height: 55px;
                box-sizing: border-box;
                border-radius: 10px;
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 28px;
                color: #000000;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 20px;
              }
              .vote {
                background: #FEE108;
                border: 1px solid #FEE108;
              }
              .approve {
                background: white;
                border: 1px solid white;
              }
              .data_desired_d5{
                display: flex;
                justify-content: space-around;
                align-items: center;
              }
            }
          }
        }
      }

      .vote_dialog {
        .vote_balance {
          .voer_lp_title {
            width: 150px;
            text-align: right;
          }
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 18px;
          color: white;
          margin: 10px 0 20px 0;
        }
        .vote_value {
          display: flex;
          align-items: center;
          justify-content: space-between;
          input {
            font-size: 18px;
            font-weight: 600;
            line-height: 36px;
            color: black;
            width: calc(100% - 80px);
            height: 30px;
            border-radius: 8px;
          }
          .value_max {
            cursor: pointer;
            width: 60px;
            height: 35px;
            border-radius: 8px;
            text-align: center;
            line-height: 35px;
            color: white;
            background-color: #e5c50a;
          }
        }
      }
    }

    .yellow {
      color: #FEE108;
    }

    .trr {
      text-align: right;
    }
  }
  @import '../../global.less';
</style>
