/**
 * 是否PC端
 * @returns {boolean}
 */
const isPC = function () {
    var userAgentInfo = navigator.userAgent
    var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
    var isPc = true
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            isPc = false
            break
        }
    }
    // true为PC端，false为手机端
    return isPc
}

/**
 * 设置小数位
 * @param numVal
 * @param numDecimalMax
 * @param roundUp
 * @param science
 * @returns {string}
 */
export function setDecimal(numVal, numDecimalMax, roundUp, science) {
    if (numVal) {
        if (science === null || science === undefined) {
            science = true
        }
        if (science && numVal.toString().indexOf(',') === -1) {
            numVal = toNonExponential(numVal)
        }
        numVal = numVal.toString()
        // 小数型
        if (numVal.indexOf('.') > -1) {
            var num
            if (numVal.substring(numVal.indexOf('.') + 1).length > numDecimalMax) {
                if (numDecimalMax === 0) {
                    num = numVal.substring(0, numVal.indexOf('.'))
                } else {
                    num = numVal.substring(0, (numDecimalMax + numVal.indexOf('.') + 1))
                }
            } else {
                num = numVal
            }
            num = num.toString()
            if (roundUp === null || roundUp === undefined) {
                roundUp = false
            }
            if (roundUp) {
                if (num.indexOf('.') > -1 && num.indexOf(',') === -1) {
                    if (parseInt(num.substring(num.indexOf('.') + 1, num.indexOf('.') + 2)) >= 5) {
                        num = parseInt(num.substring(0, num.indexOf('.'))) + 1
                    }
                }
            } else {
                if (num.indexOf('.') > -1) {
                    if (parseInt(num.substring(num.indexOf('.') + 1)) > 0) {
                        num = num.substring(0, num.indexOf('.') - 1) + parseFloat(num.substring(num.indexOf('.') - 1)).toString()
                    } else {
                        num = num.substring(0, num.indexOf('.'))
                    }
                }
            }
            return num.toString()
            // 整数型
        } else {
            if (parseFloat(numVal) === 0) {
                return '0'
            } else {
                return numVal.toString()
            }
        }
    } else {
        return '0'
    }
}

/**
 * num：待处理数字
 * precision：保留小数位
 * separator：分隔符
 */
export function formatNumber(num, precision, separator) {
    var parts;
    // 判断是否为数字
    if (!isNaN(parseFloat(num)) && isFinite(num)) {
        num = Number(num);
        // 处理小数点位数
        num = (typeof precision !== 'undefined' ? num.toFixed(precision) : num).toString();
        // 分离数字的小数部分和整数部分
        parts = num.split('.');
        // 整数部分加[separator]分隔, 借用一个著名的正则表达式
        parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (separator || ','));

        return parts.join('.');
    }
    return NaN;
}

/**
 * 科学计数法转换普通数字
 * @param num
 * @returns {string | string}
 */
export function toNonExponential(num) {
    var m = Number(num).toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    return Number(num).toFixed(Math.max(0, (m[1] || '').length - m[2]));
}

/**
 * 复制
 * @param val
 */
export function copyValue(val) {
    // 创建一个 Input标签
    const cInput = document.createElement('input')
    cInput.value = val
    document.body.appendChild(cInput)
    // 选取文本域内容
    cInput.select()
    // 执行浏览器复制命令
    document.execCommand('Copy')
    /// 复制成功后再将构造的标签 移除
    cInput.remove()
    return true
}

/**
 * 是否为NaN
 * @param val
 * @returns {boolean}
 */
export function isNaN(val) {
    if (val === '') {
        return true;
    } else {
        return false;
    }
}

/**
 * 转换时间戳
 * @param val
 */
export function conversionTimestamp(val) {
    var day = 24 * 60 * 60
    var time = 60 * 60
    var branch = 60

    var tian = 0
    var shi = 0
    var fen = 0
    var miao = 0

    tian = parseInt(val / day)
    if (tian > 0) {
        val = val - tian * day
    }
    if (val > 0) {
        shi = parseInt(val / time)
        if (shi > 0) {
            val = val - shi * time
        }
        if (val > 0) {
            fen = parseInt(val / branch)
            if (fen > 0) {
                val = val - fen * branch
            }
            miao = val
        }
    }
    return tian + ' Day ' + shi + ' H ' + fen + ' M ' + miao + ' S'
}

/**
 * 时间戳转换日期
 * @param unixtime
 * @returns {*}
 */
export function changeDate(unixtime){
    let unixTimestamp = new Date(unixtime * 1000);
    let Y = unixTimestamp.getFullYear(),
        M = ((unixTimestamp.getMonth() + 1) >= 10 ? (unixTimestamp.getMonth() + 1) : '0' + (unixTimestamp.getMonth() + 1)),
        D = (unixTimestamp.getDate() >= 10 ? unixTimestamp.getDate() : '0' + unixTimestamp.getDate()),
        h = (unixTimestamp.getHours()<10) ? "0"+unixTimestamp.getHours() : unixTimestamp.getHours(),
        min = (unixTimestamp.getMinutes()<10) ? "0"+unixTimestamp.getMinutes() : unixTimestamp.getMinutes(),
        s = (unixTimestamp.getSeconds()<10) ? "0"+unixTimestamp.getSeconds() : unixTimestamp.getSeconds();
    let toDay = Y + '-' + M + '-' + D+ " " +h + ":" +min + ":" +s;
    return toDay;
}

const toolUtils = {
    isPC,
    setDecimal,
    formatNumber,
    toNonExponential,
    copyValue,
    isNaN,
    conversionTimestamp,
    changeDate
}

export default toolUtils
