// debug  true:测试  false:正式, "54.254.254.47"
// const devUrl = ["localhost", "127.0.0.1", "192.168.2.29"];
const devUrl = ["192.168.2.29"];

const getEnv = () => {
    const link = window.location.href;
    let isDebug = false;
    for (let k in devUrl) {
        if (link.indexOf(devUrl[k]) !== -1) {
            isDebug = true;
        }
    }
    return isDebug;
}

let proiectsArrayInfo = getEnv() ?
    {
        // 测试
        'BSC': [
            {
                indexNum: 1,
                isStart: false,
                isEnd: false,
                isJoin: false,
                authorizedLimit: 0,
                icon: require('@/assets/images/cheers.png'),
                poolName: '',
                poolAddress: '0x7809cB90470d68Cd9A64b153e7c02b3E0af6AF70',
                tokenName: 'TFI',
                tokenAddress: '0x0d2A71996B347737EC6a6295d9eB5d07cB70e254',
                hardCap: 0,
                currentlyRaised: 0,
                surplus: 0,
                myVote: 0,
                upperLimit: 0,
                lowerLimit: 0,
                startTime: 0,
                endTime : 0,
                desiredIdoPlatform: [
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    },
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    },
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    }
                ],
                vision: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                intro: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                team: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                socialMedia: [
                    {
                        img: require('@/assets/images/telegram.png'),
                        link: ''
                    },
                    {
                        img: require('@/assets/images/twitter.png'),
                        link: ''
                    }
                ],
                milestone: {
                    text1: '2021-1-1',
                    text2: 'Go online!',
                    text3: '2021-1-1',
                    text4: 'Go online!',
                    text5: 'Fix Website',
                }
            },
            {
                indexNum: 2,
                isStart: false,
                isEnd: false,
                isJoin: false,
                authorizedLimit: 0,
                icon: require('@/assets/images/cheers.png'),
                poolName: '',
                poolAddress: '0x7352e4A27274cC9c21A6F9B79e08E0255b764f86',
                tokenName: 'TFI',
                tokenAddress: '0x0d2A71996B347737EC6a6295d9eB5d07cB70e254',
                hardCap: 0,
                currentlyRaised: 0,
                surplus: 0,
                myVote: 0,
                upperLimit: 0,
                lowerLimit: 0,
                startTime: 0,
                endTime : 0,
                desiredIdoPlatform: [
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    },
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    },
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    }
                ],
                vision: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                intro: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                team: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                socialMedia: [
                    {
                        img: require('@/assets/images/telegram.png'),
                        link: ''
                    },
                    {
                        img: require('@/assets/images/twitter.png'),
                        link: ''
                    }
                ],
                milestone: {
                    text1: '2021-1-1',
                    text2: 'Go online!',
                    text3: '2021-1-1',
                    text4: 'Go online!',
                    text5: 'Fix Website',
                }
            },
            {
                indexNum: 3,
                isStart: false,
                isEnd: false,
                isJoin: false,
                authorizedLimit: 0,
                icon: require('@/assets/images/cheers.png'),
                poolName: '',
                poolAddress: '0xcde255a01767FE432880fF37178C1D696eaa9715',
                tokenName: 'TFI',
                tokenAddress: '0x0d2A71996B347737EC6a6295d9eB5d07cB70e254',
                hardCap: 0,
                currentlyRaised: 0,
                surplus: 0,
                myVote: 0,
                upperLimit: 0,
                lowerLimit: 0,
                startTime: 0,
                endTime : 0,
                desiredIdoPlatform: [
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    },
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    },
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    }
                ],
                vision: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                intro: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                team: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                socialMedia: [
                    {
                        img: require('@/assets/images/telegram.png'),
                        link: ''
                    },
                    {
                        img: require('@/assets/images/twitter.png'),
                        link: ''
                    }
                ],
                milestone: {
                    text1: '2021-1-1',
                    text2: 'Go online!',
                    text3: '2021-1-1',
                    text4: 'Go online!',
                    text5: 'Fix Website',
                }
            }
        ],
        'ETH': [],
        'AVAX': [
            {
                indexNum: 1,
                isStart: false,
                isEnd: false,
                isJoin: false,
                authorizedLimit: 0,
                icon: require('@/assets/images/cheers.png'),
                poolName: '',
                poolAddress: '0xb923919fa6Df7675aa33069425aD42878BF39206',
                tokenName: 'DAAN',
                tokenAddress: '0xbc14b77a9f781281232FA92Bf676A48BA70a3A1D',
                hardCap: 0,
                currentlyRaised: 0,
                surplus: 0,
                myVote: 0,
                upperLimit: 0,
                lowerLimit: 0,
                startTime: 0,
                endTime : 0,
                desiredIdoPlatform: [
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    },
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    },
                    {
                        img: require('@/assets/images/7065216.png'),
                        text: 'Gate.io'
                    }
                ],
                vision: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                intro: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                team: 'TrustFi Booster will serve as a community-driven and on-chain sponsor to realize an efficient match between startups and launchpads.',
                socialMedia: [
                    {
                        img: require('@/assets/images/telegram.png'),
                        link: ''
                    },
                    {
                        img: require('@/assets/images/twitter.png'),
                        link: ''
                    }
                ],
                milestone: {
                    text1: '2021-1-1',
                    text2: 'Go online!',
                    text3: '2021-1-1',
                    text4: 'Go online!',
                    text5: 'Fix Website',
                }
            }
        ],
        'POLYGON': []
    }
    :
    {
        // 正式
        'BSC': [
            {
                indexNum: 1,
                isStart: false,
                isEnd: false,
                isJoin: false,
                authorizedLimit: 0,
                icon: 'https://www.linkpicture.com/q/coxena.logo.jpg',
                poolName: '',
                poolAddress: '0x42cD297E40Ce1d9D0a1Fad59caAc3aD462d2bf2a',
                tokenName: 'BUSD',
                tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
                hardCap: 0,
                currentlyRaised: 0,
                surplus: 0,
                myVote: 0,
                upperLimit: 0,
                lowerLimit: 0,
                startTime: '10:00 AM, Jan. 21 2022',
                endTime : '02:00 PM, Jan. 24 2022',
                isVision: false,
                isIntro: true,
                isTeam: false,
                isTokenPrice: true,
                isSocialMedia: true,
                isMilestone: false,
                isUsageOfFundRaised: false,
                desiredIdoPlatform: [
                    // {
                    //     img: '',
                    //     text: ''
                    // },
                    // {
                    //     img: '',
                    //     text: ''
                    // },
                    // {
                    //     img: '',
                    //     text: ''
                    // }
                ],
                vision: '',
                intro: 'Coxena is an all-in-one platform around CeFi and DeFi. The Coxena\'s solution integrates services including a P2P exchange, spot and instant-swap facets, savings accounts, lending options, POS systems, crowdfunding platform, and wallet management system, all on a single platform. Primary, it aims to serve African crypto users while extending its reach globally as it scales. It has already started building on tier-1 CeFi solutions\' infrastructure to bring highly competitive liquidity to its users.',
                team: '',
                tokenPrice: '1$COX=0.01$, 10% release at TGE, 1-month lock, and linear vesting for 12 months',
                socialMedia: [
                    {
                        img: 'https://www.linkpicture.com/q/coxena.logo.jpg',
                        link: 'https://www.coxena.com'
                    },
                    {
                        img: require('@/assets/images/twitter.png'),
                        link: 'https://twitter.com/coxenacox'
                    },
                    {
                        img: require('@/assets/images/telegram.png'),
                        link: 'https://t.me/Coxena'
                    }
                ],
                milestone: {
                    text1: '2:00 PM UTC 1.20 2022',
                    text2: 'Start Time',
                    text3: '2:00 PM UTC 1.24 2022',
                    text4: 'End Time',
                    text5: 'Complete!',
                }
            },
          {
            indexNum: 2,
            isStart: false,
            isEnd: false,
            isJoin: false,
            authorizedLimit: 0,
            icon: 'https://www.linkpicture.com/q/coxena.logo.jpg',
            poolName: '',
            poolAddress: '0x953807813ee8eb31c234616b7f7dd07e0c6dd04a',
            tokenName: 'BUSD',
            tokenAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
            hardCap: 0,
            currentlyRaised: 0,
            surplus: 0,
            myVote: 0,
            upperLimit: 0,
            lowerLimit: 0,
            startTime: '03:00 PM, Jan. 31 2022',
            endTime : '03:00 PM, Feb. 2 2022',
            isVision: false,
            isIntro: true,
            isTeam: false,
            isTokenPrice: true,
            isSocialMedia: true,
            isMilestone: false,
            isUsageOfFundRaised: false,
            desiredIdoPlatform: [],
            vision: '',
            intro: 'Coxena is an all-in-one platform around CeFi and DeFi. The Coxena\'s solution integrates services including a P2P exchange, spot and instant-swap facets, savings accounts, lending options, POS systems, crowdfunding platform, and wallet management system, all on a single platform. Primary, it aims to serve African crypto users while extending its reach globally as it scales. It has already started building on tier-1 CeFi solutions\' infrastructure to bring highly competitive liquidity to its users.',
            team: '',
            tokenPrice: '1$COX=0.01$, 10% release at TGE, 1-month lock, and linear vesting for 12 months',
            socialMedia: [
              {
                img: 'https://www.linkpicture.com/q/coxena.logo.jpg',
                link: 'https://www.coxena.com'
              },
              {
                img: require('@/assets/images/twitter.png'),
                link: 'https://twitter.com/coxenacox'
              },
              {
                img: require('@/assets/images/telegram.png'),
                link: 'https://t.me/Coxena'
              }
            ],
            milestone: {
              text1: '03:00 PM, Jan. 31 2022',
              text2: 'Start Time',
              text3: '03:00 PM, Feb. 2 2022',
              text4: 'End Time',
              text5: 'Complete!',
            }
          }
        ],
        'ETH': [],
        'AVAX': [],
        'POLYGON': []
    };

export default proiectsArrayInfo;
